import * as React from "react"
// import { Link, graphql } from "gatsby"
import { Link, useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import Masonry from 'react-masonry-css'

import Seo from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
  query {
    allFile(filter: {relativeDirectory: {eq: "masonry"}}
    sort: { fields: name }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            quality: 80
            layout: FULL_WIDTH
          )
        }
      }
    }
  }   
`)

  const breakpointColumnsObj = {
    default: 3,
    // 1100: 3,
    // 700: 2,
    // 500: 1,
    350: 1,
    750: 2,
    900: 3
  };

  return (
    <>
      <Seo title="Accueil" />
      <div className="px-2">
        <StaticImage
          src="../images/home/home.webp"
          layout="fullWidth"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="card"
        />
      </div>

      <div className="font-roboto mx-auto max-w-7xl px-4">
        <div className="tracking-tight py-10">
          <div className="pt-6">
            <h2 className="font-bold py-4 text-4xl">
              Créer votre équipement textile
            </h2>
            <p className="text-justify py-4 text-gray-800">
              Offrez-vous des textiles sportifs élégants et d’excellente qualité
              aux couleurs de vos clubs, accentuez le marketing visuel de vos
              entreprises et collectivités, donner un support visuel à vos
              évènements associatifs, portez haut votre marque de vêtement et
              d’accessoires.
            </p>
          </div>

          <div className="pt-8">
            <h2 className="font-semibold py-4 text-2xl text-gray-800">
              Une aventure débutée en 2013 qui a mis en lumière
            </h2>
            <p>
              <span className="font-semibold text-justify">Constat:</span> Une
              attente client axée sur une demande de produits de qualité, de
              service commercial à l'écoute et proactif, proposants des prix
              ultra compétitifs.
            </p>

            <p className="mt-4">
              <span className="font-semibold text-justify">Action:</span> Afin
              de répondre à ces exigences, durant des années nous avons accentué
              notre connaissance du terrain. <br />
              Nous sommes attaché à nous entourer de fournisseurs possédant un haut
              degré d’exigence professionnelle et de valeurs éthiques.
            </p>

            <p className="mt-4">
              <span className="font-semibold text-justify">Réponse:</span>{" "}
              <Link to="" className="font-semibold text-blue-500">
                Maxhel Consulting & Trading
              </Link>{" "}
              vous permet d'accéder à un choix d’équipement textile alliant
              nouvelles technologies, élégance et personnalisation.
            </p>
          </div>

          <div className="mx-auto max-w-md grid grid-cols-1 md:max-w-7xl md:gap-8 md:grid-cols-3">
            <div className="pt-12">
              <StaticImage
                src="../images/embroidery/broderie.webp"
                aspectRatio={16 / 9}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="card"
              />
            </div>

            <div className="pt-12">
              <StaticImage
                src="../images/embroidery/broderie2.webp"
                aspectRatio={16 / 9}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="card"
              />
            </div>
            <div className="pt-12">
              <StaticImage
                src="../images/embroidery/broderie3.webp"
                aspectRatio={16 / 9}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="card"
              />
            </div>
          </div>
        </div>

        <div className="tracking-tight pt-12">
          <h2 className="font-bold py-4 text-4xl">
            Nos catégories d'équipements textiles à personnaliser
          </h2>
          <p className="text-justify py-4 text-gray-800">
            Maxhel élabore vos équipements selon vos souhaits ou modèles (forme,
            matière, couleur) et les personnalise selon vos envies.
            </p>
          <p className="text-justify max-w-5xl pt-2 pb-8 text-gray-800">
            Sérigraphie, broderie, sublimation, impression, écusson, Maxhel est
            en mesure de sublimer, d’habiller tout équipement sportif <br />
            (maillots, shorts, chaussettes, sacs, serviettes) mais également tee-shirts,
            casquettes, polos, doudounes, sweat shirts, chemises, pantalons, ...
          </p>

          <div className="relative overflow-auto">
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column">
              {data.allFile.nodes.map((node, i) => (
                <div key={i}>
                  <img
                    src={node.childImageSharp.gatsbyImageData.images.fallback.src}
                    alt={node.childImageSharp.gatsbyImageData.images.fallback.name}
                  />
                </div>
              ))}
            </Masonry>
            <div className="bg-white w-full bottom-0 absolute md:h-24"></div>
          </div>
        </div>

        <div className="mt-16 tracking-tight md:mt-0">
          <h2 className="font-semibold font-lator text-justify leading-none tracking-tighter max-w-2xl text-3xl text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="-mt-8 -ml-20 top-0 left-0 text-blue-gray-100 w-32 z-0 absolute hidden sm:block lg:-mt-10 lg:-ml-28 lg:w-32"
              >
                <defs>
                  <pattern
                    id="2feffae2-9edf-414e-ab8c-f0e6396a0fc1"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#2feffae2-9edf-414e-ab8c-f0e6396a0fc1)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="text-indigo-500 relative">Notre</span>
            </span>{' '}
            <span className="text-indigo-500">expérience</span> du terrain nous permet de répondre au mieux à <span className="text-green-500">votre demande</span>, nos atouts nous permettent de vous proposer un <span className="text-blue-500">service plus</span>.
          </h2>
        </div>


        <div className="tracking-tight pt-12 pb-20">
          <div className="md:flex md:space-x-6 md:justify-center md:justify-items-center">
            <div className="md:w-1/2">
              <h2 className="font-bold text-justify py-4 text-4xl">Pourquoi ?</h2>
              <p className="text-justify">Nombre d'associations sportives ou culturelles font face aux mêmes difficultés à savoir
              une trésorerie volatile portée uniquement par les cotisations des adhérents et des
              quelques subventions obtenues.
                 </p>
            </div>
            <div className="py-8 md:py-0 md:w-1/2">
              <h2 className="font-bold text-justify py-4 text-4xl">Comment ?</h2>
              <p className="text-justify">En vous proposant une gamme de produits sportswear chic et élégant mais également des accessoires tels que sacs de sports, casquettes,
              bonnets, écharpes etc ... personnalisables à vos couleurs afin de créer votre boutique de club,
              fidéliser votre propre clientèle et être enfin en mesure de piloter sainement votre trésorerie.
              </p>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default IndexPage

